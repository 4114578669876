import './SearchBar.css';

import { useNavigate, createSearchParams } from 'react-router-dom';
import { useState} from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Mappings
import * as mobileMap from '../../mappings/mobile_mappings';

export default function SearchBar() {
  console.log(`Gemini api key ` + process.env.REACT_APP_GEMINI_API_KEY);

  let navigate = useNavigate();
  let [activeBrand, setActiveBrand] = useState({ key: '', name: ''});
  let [activeModel, setActiveModel] = useState({ key: '', name: ''});
  let [isModelSelectionDisabled, setModelSelectionDisabled] = useState(true);
  let [isButtonDisabled, setButtonDisabled] = useState(true);

  function goTo(page : string, state: any) {
    navigate({
      pathname: page,
      search: `?${createSearchParams(state)}`
    });
  }

  let brands:any[] = [];
  let models:any[] = [];

  for (const brand of Object.keys(mobileMap.MOBILE_MODELS)) {
    brands.push({
      key: brand,
      name: mobileMap.MOBILE_BRANDS[brand]
    });
  }

  if (!isModelSelectionDisabled && activeBrand.key !== '') {
    let brandModels = mobileMap.MOBILE_MODELS[activeBrand.key]
    for (const [modelKey, modelName] of Object.entries(brandModels)) {
      // console.log(activeBrand.key +" " + modelKey);

      if (mobileMap.MOBILE_VARIATIONS[activeBrand.key as keyof Object] &&
          mobileMap.MOBILE_VARIATIONS[activeBrand.key as keyof Object][modelKey as keyof Object]) {
        let modelVariations:String[] = mobileMap.MOBILE_VARIATIONS[activeBrand.key as keyof Object][modelKey as keyof Object];
        for(var model of modelVariations) {
          models.push({key: modelKey, name: model})
        }
      }
      else {
        models.push({key: modelKey, name: modelName});
      }
    }
  }

  return (
    <div className='flex flex-col sm:flex-row items-center justify-center gap-4'>
      <Autocomplete
        className='flex-auto w-64'
        disablePortal
        onChange={(event: any, newValue: any | null) => {
          if (newValue) {
            setActiveBrand(newValue);
            setModelSelectionDisabled(newValue.name === '');
          } else
          {
            setModelSelectionDisabled(true);
          }
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#f9f9f9', // Light background color for dropdown
          },
          '& .MuiInputBase-root': {
            backgroundColor: '#ffffff', // Light background color for input field
          },
          '& .MuiAutocomplete-option': {
            '&[aria-selected="true"]': {
              backgroundColor: '#e0f7fa', // Highlight selected option
            },
          },
        }}
        options={brands.sort((a, b) => -b.name[0].localeCompare(a.name[0]))}
        groupBy={(option) => option.name[0]}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Brand" />}
      />

      <Autocomplete
        className='flex-auto w-64'
        disabled={isModelSelectionDisabled}
        onChange={(event: any, newValue: any | null) => {
          if (newValue) {
            setActiveModel(newValue);
            setButtonDisabled(newValue.name === '');
          } else
          {
            setButtonDisabled(true);
          }
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#f9f9f9', // Light background color for dropdown
          },
          '& .MuiInputBase-root': {
            backgroundColor: '#ffffff', // Light background color for input field
          },
          '& .MuiAutocomplete-option': {
            '&[aria-selected="true"]': {
              backgroundColor: '#e0f7fa', // Highlight selected option
            },
          },
        }}
        options={models}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Model" />}
      />
      <Button
        className='flex-none w-32'
        disabled={isButtonDisabled}
        onClick={() => {
          goTo('/Information', {make: activeBrand.name, model:activeModel.name});
        }}
        sx={{
          backgroundColor: '#f0f0f0', // Light background color
          color: '#000', // Text color for contrast
          '&:hover': {
            backgroundColor: '#e0e0e0', // Slightly darker on hover
          },
        }}
      >
        Go!
        </Button>
    </div>
  );
}