import './HomePage.css';

import { Link, useNavigate, createSearchParams } from 'react-router-dom';

import SearchBar from '../searchbar/SearchBar';

const initialCars = [
  {
    make: 'Mercedes-Benz',
    model: 'C 63 AMG'
  },
  {
    make: 'BMW',
    model: 'M6'
  },
  {
    make: 'Audi',
    model: 'Rs4'
  },
]

export function HomePage() {
  let navigate = useNavigate();

  function goTo(page : string, state: any) {
    navigate({
      pathname: page,
      search: `?${createSearchParams(state)}`
    });
  }

  return (
    <div className="home-page">
      <div className="hero-section">
        <h1>Check your Dream Car</h1>
        <p>Explore the market trends and find out if it is the perfect onSearch for you.</p>
        <SearchBar></SearchBar>
      </div>
      <div className="featured-section">
        <h2 style={{textAlign: 'center'}}>Most Visited Cars</h2>
        <div className="car-grid">
          {initialCars.map((car: any, index: any)=>{
            return <div onClick={()=>{goTo('/information', {make: car.make, model: car.model})}} className='card-link' key={index}>
                    <div className="car-info-card">
                      <i className="fas fa-car"></i>
                      <h3>{car.make} {car.model}</h3>
                      <p>Choose from hundreds of cars from top brands and dealerships.</p>
                    </div>
                  </div>
          })}
        </div>
      </div>
      <div className="cta-section">
        <h2>Ready to Get Started?</h2>
        <p>Explore our selection of cars and find your dream car today!</p>
        <Link to="/cars" className="explore-button">Explore Cars</Link>
      </div>

      <div className="cta-section">
        <h3>Contact us:</h3>
          <div>
            <a href="viber://chat/?number=%2B359883506455">
              <img className='contact-card-img' src="img/viber/logo_icon_purple_small.png" alt='Viber chat'/>
            </a>
          </div>
      </div>
    </div>
  );
}
